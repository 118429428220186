;(function ($) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Accordion = function(scope){
        scope = scope || document;
        scope = $(scope);

        function raiseChangeEvent(source){
            if (!Array.isArray(source) && !(source instanceof $)) source = [source];
            
            if ("createEvent" in document) {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                for(var i = 0; i < source.length; i++)
                    source[i].dispatchEvent(evt);
            }
            else{
                for(var i = 0; i < source.length; i++)
                    source[i].fireEvent("onchange");
            }    
        }

        var toggle_icon = '<i class="curtin-icon-arrow-wide-down"></i>';
        
        // ------------------------------------------------------------------
        // Accordion
        // ------------------------------------------------------------------
        var all_accordion_panels = scope.find('.accordion > .accordion__item > .accordion__item__content').hide(); // Hide all accordion panels
        var accordion_header = scope.find('.accordion .accordion__item__header a');

        $(accordion_header).append(toggle_icon); // Add toggle icon to the accordion header

        scope.find('.accordion .accordion__item__header a').on('click', function (e) {
            $(this).toggleClass('is-expanded');
            $(this).children('i').toggleClass('curtin-icon-arrow-wide-down curtin-icon-arrow-wide-up');
            $(this).parent('.accordion__item__header').next().slideToggle(200);
            
            raiseChangeEvent($(this).parents('.accordion')[0])

            e.preventDefault();
            return false;
        });
    }
}(window.jQuery));