;(function ($) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Footer = function(scope){
        scope = scope || document;
        scope = $(scope);

        var toggle_icon = '<i class="curtin-icon-arrow-wide-down"></i>';

        // Add + sign to the toggle
        scope.find('.news-toggle .button').append(toggle_icon);

        // Clone h3 heading and apply .button style for toggle (for mobile)
        scope.find('.page-footer .slide-toggle .page-footer__content__title').after(function (e) {
            return $(this).clone().removeClass('page-footer__content__title').addClass('button').append(toggle_icon);
        });

        // Toggle buttons
        scope.find('.slide-toggle > .button').on('click', function (e) {
            $(this).toggleClass('is-expanded');
            $(this).children('i').toggleClass('curtin-icon-arrow-wide-down curtin-icon-arrow-wide-up');
            $(this).parent('.slide-toggle').find('.slide-toggle__content').slideToggle(200);
            e.preventDefault();
            return false;
        });
    }
}(window.jQuery));