;(function ($, window) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Tabs = function(scope){
        scope = scope || document;
        scope = $(scope);
        
        function raiseChangeEvent(source){
            if (!Array.isArray(source) && !(source instanceof $)) source = [source];
            
            if ("createEvent" in document) {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                for(var i = 0; i < source.length; i++)
                    source[i].dispatchEvent(evt);
            }
            else{
                for(var i = 0; i < source.length; i++)
                    source[i].fireEvent("onchange");
            }    
        }

        // ------------------------------------------------------------------
        // Tab
        // ------------------------------------------------------------------
        scope.find('.tab').each(function (i) {
            var tab = $(this);

            // Activates the first tab for desktop
            tab.find('.tab__nav > div:first').addClass('is-active');
            tab.find('.tab__item__content:first').addClass('is-active');

            // Tab Toggle
            tab.find('.tab__nav > div').each(function(i){
                var tab_nav = $(this);
                var tab_id = tab_nav.attr('data-tab');
                var tab_item = tab.find("#" + tab_id);
                var header = tab_item.find(".tab__item__header");
                if (!header.length){
                    var header = $("<h4>").html(tab_nav.html()).addClass("tab__item__header");
                    tab_item.prepend(header);
                }

                var click_handler = function (e) {
                    tab.find('.tab__nav > div').removeClass('is-active');
                    tab.find('.tab__item__content').removeClass('is-active');

                    tab_nav.addClass('is-active');
                    tab.find("#" + tab_id +".tab__item .tab__item__content").addClass('is-active');

                    raiseChangeEvent(tab);
                    
                    e.preventDefault();
                    return false;
                }

                tab_nav.click(click_handler);
                header.find("a").click(click_handler);
            });
        });

        // Replace tab classess with accordion if the width is under 600px
        $(window).resize(function () {
                var width = $(window).width();

                if (width < 600) {
                    scope.find('.tab').addClass('accordion').addClass('js-tab');
                    scope.find('.tab__item').addClass('accordion__item');
                    scope.find('.tab__item__header').addClass('accordion__item__header');
                    scope.find('.tab__item__content').addClass('accordion__item__content');
                } else {
                    scope.find('.js-tab.accordion').removeClass('accordion').addClass('tab');
                    scope.find('.js-tab .accordion__item').removeClass('accordion__item').addClass('tab__item');
                    scope.find('.js-tab .accordion__item__header').removeClass('accordion__item__header').addClass('tab__item__header');
                    scope.find('.js-tab .accordion__item__content').removeClass('accordion__item__content').addClass('tab__item__content');
                }
            })
            .resize(); //trigger the resize event on page load.
    }
}(window.jQuery, window));