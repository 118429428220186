;(function ($) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Navigation = function(scope){
        scope = scope || document;
        scope = $(scope);
        
        // ------------------------------------------------------------------
        // Visually hide some of the primary navigation items
        // if it goes two lines
        // ------------------------------------------------------------------

        var hideToggle    = true;
        var checkWidth    = 0;
        var wrapperWidth  = scope.find('.site-navi .wrapper').width();
        var showMoreWidth = scope.find('.site-navi .show-nav-more').width();
        var total         = scope.find('.site-navi__layout > li:visible').length;

        wrapperWidth = wrapperWidth - showMoreWidth;

        scope.find('.site-navi__layout > li:visible').each(function(index){
        checkWidth += $(this).outerWidth();
        if( wrapperWidth < checkWidth) {
            $(this).addClass('is-hide');
            hideToggle = false;

            // Show the last item if the width is less than the wrapper width
            if( (index === total - 1) && (checkWidth <= wrapperWidth + showMoreWidth ) ) {
            $(this).removeClass('is-hide');
            hideToggle = true;
            }

        }
        });

        if(!hideToggle) {
            scope.find('.site-navi__layout .show-nav-more').css('display', 'block');
        }

        scope.find('.site-navi__layout .show-nav-more, .site-navi__layout .hide-nav-more').removeClass('is-hide');        
    }
}(window.jQuery));