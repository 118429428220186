;(function ($, window) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Smoothscroll = function(scope){
        scope = scope || document;
        scope = $(scope);
        
        // ------------------------------------------------------------------
        // Smooth Scrolling
        // ------------------------------------------------------------------
        scope.find('a[href*="#"]:not([href="#"],[rel*="modal:"],[no-scroll]), [rel]:not([rel*="modal:"], [no-scroll])').click(function () {
            if (window.Curtin && window.Curtin.disableSmoothScroll)
                return true;

            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    }
}(window.jQuery, window));