;(function ($) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Header = function(scope){
        scope = scope || document;

        // ------------------------------------------------------------------
        // Jump to content link - Keyboard navigate to content
        // ------------------------------------------------------------------
        var jumpToContentLinks = scope.querySelectorAll('a[href="#skip-to-content"]');

        var jumpToContentHandler = function(){
            var content = scope.getElementById("skip-to-content");
            if (content){
                content.setAttribute('tabindex', '-1');
                content.focus();
                content.removeAttribute('tabindex');                
            }
        }

        for(var i = 0; i < jumpToContentLinks.length; i++){
            jumpToContentLinks[i].addEventListener('click', jumpToContentHandler);
        }

        // ------------------------------------------------------------------
        // Top navigation - Keyboard navigatable sub menus
        // ------------------------------------------------------------------
        function submenuFocus(menuRoot){
            return function(e){
                menuRoot.classList.add('is-selected');
            }            
        }

        function submenuUnfocus(menuRoot){
            return function(e){
                menuRoot.classList.remove('is-selected');
            }
        }

        var headerNav = scope.getElementsByClassName('site-navi__layout');

        for(var i = 0; i < headerNav.length; i++){
            var headerRootItems = headerNav[i].querySelectorAll('.site-navi__layout > li');
            for(var j = 0; j < headerRootItems.length; j++){
                var menuRoot = headerRootItems[j];
                var subNavItems = menuRoot.getElementsByTagName('li');
                
                for(var k = 0; k < subNavItems.length; k++){
                    subNavItems[k].addEventListener('focusin', submenuFocus(menuRoot));
                    subNavItems[k].addEventListener('focusout', submenuUnfocus(menuRoot));
                }
            }
        }
        // ------------------------------------------------------------------
        // Top navigation - mobile view
        // ------------------------------------------------------------------

        // Search icon toggle
        var searchButtons = scope.getElementsByClassName('toggle-icon--search');
        
        for(var i = 0; i < searchButtons.length; i++){
            searchButtons[i].addEventListener('click', function(e){
                $(scope).find('.site-search').slideToggle(100);
                this.classList.toggle('is-active');
                var jsCf = scope.getElementsByClassName('js-cf');
                for(var j = 0; j < jsCf.length; j++){
                    jsCf[j].classList.toggle('cf')
                }
                e.preventDefault();
                return false;
            })
        }
    }
}(window.jQuery));