;(function ($) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.ResponsiveMenu = function(scope){
        scope = scope || document;

        function insertAfter(element, target){
            target.parentNode.insertBefore(element, target.nextSibling);
        }

        function isAncestor(element, target, root){
            root = root || document.body;
            while(element && element !== root && element != target){
                element = element.parentNode;
            }
            return element == target;
        }

        // Insert toggle HTML
        var subsiteHeader = scope.getElementsByClassName('subsite-header');
        
		//if there is no subsite title, add one after the global bar, 
        if (!subsiteHeader.length){
        	var newSubsiteHeader = document.createElement('div');
        	newSubsiteHeader.classList.add('subsite-header--mobile');
        	var subsiteHeaderWrapper = document.createElement('div');
        	subsiteHeaderWrapper.classList.add('wrapper');
        	newSubsiteHeader.appendChild(subsiteHeaderWrapper);

            var header = scope.getElementsByClassName('page-header');
            if (header.length){
                header[0].appendChild(newSubsiteHeader);
            }

        	subsiteHeader = new Array(newSubsiteHeader);
        }

        var subsiteHeaderWrapper = subsiteHeader[0].getElementsByClassName('wrapper')[0];
        
        var existingMobileMenu = scope.getElementsByClassName('site-navi--mobile');
        //remove existing mobile menu if it already exists
        if (existingMobileMenu.length)
        for(var i = 0; i < existingMobileMenu.length; i++){
            existingMobileMenu[i].parentNode.removeChild(existingMobileMenu[i]);
        }

        var siteNav = scope.getElementsByClassName('site-navi');        
        //create if site nav exists and mobile nav does not already exist
        if (siteNav.length){
            //
            //Create Mobile menu
            //
            var hasMenuItems = false;
            var mobileMenu = document.createElement('nav');
            mobileMenu.classList.add('site-navi--mobile');
            mobileMenu.setAttribute('role', 'navigation');
            var mobileMenuLayout = document.createElement('div');
            mobileMenuLayout.classList.add('site-navi--mobile__layout');
            mobileMenu.appendChild(mobileMenuLayout);
            
            var siteNavLayout = siteNav[0].getElementsByClassName('site-navi__layout');
            if (siteNavLayout.length){
                var mobileSiteNav = siteNavLayout[0].cloneNode(true);
                var navOverflowItems = 
                //convert HTMLColllection to concatenate arrays
                    Array.prototype.slice.call(mobileSiteNav.getElementsByClassName('show-nav-more'), 0)
                    .concat(Array.prototype.slice.call(mobileSiteNav.getElementsByClassName('show-nav-less'), 0));
                
                for (var i = 0; i < navOverflowItems.length; i++){
                    mobileSiteNav.removeChild(navOverflowItems[i]);
                }

                if (mobileSiteNav.children.length){
                    hasMenuItems = true;                    
                    mobileSiteNav.className = "site-navi--mobile__navi"
                    mobileMenuLayout.appendChild(mobileSiteNav);
                }
            }

            var globalLinks = scope.getElementsByClassName('global-bar__links');
            if (globalLinks.length){
                hasMenuItems = true;
                var mobileGlobalLinks = globalLinks[0].cloneNode(true);
                mobileGlobalLinks.className = "site-navi--mobile__links"
                mobileMenuLayout.appendChild(mobileGlobalLinks);
            }

            var globalLogin = scope.getElementsByClassName('global-bar__login');
            if (globalLogin.length){
                hasMenuItems = true;
                var mobileGlobalLogin = globalLogin[0].cloneNode(true);
                mobileGlobalLogin.className = "site-navi--mobile__login"                
                mobileMenuLayout.appendChild(mobileGlobalLogin);
            }

            //add menu button if it doesn't already exist
            //<a href='#' class='toggle-icon toggle-icon--menu'><span class='visually-hidden'>Toggle Menu</span></a>;        
            if (hasMenuItems){
                if (!subsiteHeaderWrapper.getElementsByClassName('toggle-icon--menu').length){
                    var menuToggle = document.createElement('a');
                    menuToggle.setAttribute('href', '#');
                    menuToggle.classList.add('toggle-icon');
                    menuToggle.classList.add('toggle-icon--menu');

                    var toggleText = document.createElement('span');
                    toggleText.classList.add('visually-hidden');
                    toggleText.innerText = 'Toggle Menu';
                    menuToggle.appendChild(toggleText);
                    
                    subsiteHeaderWrapper.insertBefore(menuToggle, subsiteHeaderWrapper.firstChild);
                }

                var close = document.createElement('a');
                close.setAttribute('href', '#');
                close.classList.add('site-navi__close');
                close.innerText = "Close menu";
                mobileMenuLayout.appendChild(close);

                var menuToggle = subsiteHeaderWrapper.getElementsByClassName('toggle-icon--menu')[0];
                close.addEventListener('click', function(e){
                    $(mobileMenuLayout).slideToggle(200);
                    menuToggle.classList.toggle('is-active');
                    e.preventDefault();
                    return false;
                })

                //insert after the main nav
                insertAfter(mobileMenu, siteNav[0]);

                menuToggle.addEventListener('click', function (e) {
                    $(mobileMenuLayout).slideToggle(200);
                    menuToggle.classList.toggle('is-active');
                    e.preventDefault();
                    return false;
                });                
            }

            //
            //Add sub nav to mobile site nav
            //
            var subNav = scope.querySelector('.sub-navi__layout > li');
            if (subNav){
                var targetPath = subNav.querySelector('li > a').getAttribute('href');
                var mobileMenuTargetItemLink = mobileMenuLayout.querySelector('li.has-subnav a[href="'+ targetPath +'"]');

                if (mobileMenuTargetItemLink){
                    var mobileMenuTargetItem = mobileMenuTargetItemLink.parentNode;
                    var subNavMobile = subNav.querySelector('li > ul').cloneNode(true);
                    var existingSubNav = mobileMenuTargetItem.querySelector('.has-subnav > div > ul, .has-subnav > ul');

                    if (subNavMobile && existingSubNav){
                        existingSubNav.parentNode.replaceChild(subNavMobile, existingSubNav);
                    }
                }

                var subNavItems = subNavMobile.getElementsByTagName('li');
                for(var i = 0; i < subNavItems.length; i++){
                    var subNavItem = subNavItems[i];
                    subNavItem.className = subNavItem.querySelector('li > div > ul, li > ul') ? 'has-subnav' : '';
                }
            }
            //
            // Add sub menu toggle icon
            //
            var hasSubNavItems = mobileSiteNav.getElementsByClassName('has-subnav');

            var selectIconHandler = function(e){
                var subMenuSelector = '.has-subnav > div > ul, .has-subnav > ul';
                for(var i = 0; i < hasSubNavItems.length; i++){
                    var targetSubNavItem = this.parentNode;
                    var currentSubNavItem = hasSubNavItems[i];

                    if (targetSubNavItem == currentSubNavItem){
                        currentSubNavItem.classList.toggle('is-selected');
                        $(currentSubNavItem.querySelector(subMenuSelector)).slideToggle(200);
                    }
                    else if(isAncestor(targetSubNavItem, currentSubNavItem, mobileSiteNav)){
                        currentSubNavItem.classList.add('is-selected');
                        $(currentSubNavItem.querySelector(subMenuSelector)).slideDown(200);
                    }
                    else{                       
                        currentSubNavItem.classList.remove('is-selected');
                        $(currentSubNavItem.querySelector(subMenuSelector)).slideUp(200);
                    }
                }

                e.preventDefault();
                return false;            
            }

            for(var i = 0; i < hasSubNavItems.length; i++){
                var subNavItem = hasSubNavItems[i];
                var subNavItemLink = subNavItem.querySelector('.has-subnav > a');
                var selectIcon = document.createElement('button');
                selectIcon.classList.add('select-toggle');
                var toggleText = document.createElement('span');
                toggleText.classList.add('visually-hidden');
                toggleText.innerText="Toggle Menu";

                selectIcon.appendChild(toggleText);
                insertAfter(selectIcon, subNavItemLink);

                selectIcon.addEventListener('click', selectIconHandler);
            }
        }
    }
}(window.jQuery));