;(function ($) {
    'use strict';

    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};
    window.Curtin.Design.Dropdown = function(scope){
        scope = scope || document;
        scope = $(scope);
        // ------------------------------------------------------------------
        // Dropdown toggle
        // ------------------------------------------------------------------

        scope.find('.dropdown__button').on('click', function (e) {
            $(this).parent('.dropdown').find('.dropdown__menu').slideToggle(200);
            $(this).toggleClass('is-expanded');
            $(this).find('i').toggleClass('curtin-icon-arrow-wide-down curtin-icon-arrow-wide-up');
            e.preventDefault();
            return false;
        });


        // ------------------------------------------------------------------
        // Close a dropdown menu with a click anywhere else on the page
        // Will be nice to add tap event too
        // ------------------------------------------------------------------

        $('body').on('click', function () {
            scope.find('.dropdown .dropdown__menu').slideUp(200);
            scope.find('.dropdown .dropdown__button').removeClass('is-expanded');
            scope.find('.dropdown .dropdown__button__toggle i').removeClass('curtin-icon-arrow-wide-up').addClass('curtin-icon-arrow-wide-down');
        });
    }
}(window.jQuery));