;(function (window){
    window.Curtin = window.Curtin || {};
    window.Curtin.Design = window.Curtin.Design || {};

    if (!window.Curtin.InitDesign){
        window.Curtin.InitDesign = function(scope){
            scope = scope || [document];
            if (!scope.length){
                scope = [scope];
            }

            for (var func in window.Curtin.Design) {
                if (window.Curtin.Design.hasOwnProperty(func)) {
                    for(var i = 0; i < scope.length; i++){
                        window.Curtin.Design[func](scope[i]);
                    }
                }
            }
        }
    }

    $(function () {
        window.Curtin.InitDesign();
    });
    
}(window));